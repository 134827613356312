import React, { FC } from "react";
import styles from "./QuestionCard.module.scss";
import { QuestionCardProps } from "Maya/types";

/**
 * QuestionCard component renders a button that displays a question and title.
 * When clicked, it sets the input field's value to the provided prompt and
 * triggers the form submission.
 *
 * @param {QuestionCardProps} props - The properties for the QuestionCard component.
 * @param {string} props.title - The title to be displayed on the card.
 * @param {string} props.question - The question text to be displayed on the card.
 * @param {string} props.prompt - The prompt to set as the input field's value.
 * @param {function} props.handleSubmit - The function to call when the form is submitted.
 * @param {React.RefObject<HTMLInputElement>} props.inputRef - The ref to the input field.
 */
export const QuestionCard: FC<QuestionCardProps> = ({
    title,
    question,
    handleSubmit,
    inputRef
}) => {

    /**
     * Handles the button click event by setting the input field's value
     * to the provided prompt and triggering the form submission.
     *
     * @param prompt - The string to set as the input field's value.
     */
    const handleButtonClick = (question: string) => {
        inputRef.current.value = question;
        handleSubmit(null);
    };

    return (
        <button
            type="button"
            className={styles.questionCardContainer}
            onClick={() => handleButtonClick(`${title} ${question}`)}
        >
            <p className={styles.questionText}>
                <b>{title}</b> {question}
            </p>
            <svg className={styles.icon} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25">
                    <circle cx="12.5" cy="12.987" r="11.523" stroke="#0A66C2" strokeWidth=".954" />
                    <path
                        stroke="#0A66C2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.466 12.987h13.338m-5.197 5.197 5.197-5.197-5.197-5.198"
                    />
            </svg>
        </button>
    );
};
