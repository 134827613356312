/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC } from "react";
import { InputProps } from "Maya/types";
import styles from "./Input.module.scss";

/**
 * Input component for handling user queries related to job advice.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.handleSubmit - Function to update state in the outer component.
 * @param {React.RefObject<HTMLInputElement>} props.inputRef - Reference to the input element for accessing its value.
 * @param {boolean} props.hasResults - Flag indicating if there are existing results to adjust styling.
 *
 * @returns {JSX.Element} A section containing an input form for submitting queries and a disclaimer note.
 *
 * The component uses the Maya API to fetch responses based on user input and updates the UI accordingly.
 */
export const Input: FC<InputProps> = ({ handleSubmit, inputRef, hasResults }) => {
    const placeholder = "Ask anything about career advice";

    return (
        <section className={`${styles.inputSection} ${hasResults ? styles.hasResults : ""}`}>
            <form onSubmit={(e) => handleSubmit(e)}>
                <label htmlFor="chatInput" className={styles.visuallyHidden}>
                    Chat input
                </label>
                <div className={styles.inputWrapper}>
                    <input
                        ref={inputRef}
                        type="text"
                        id="chatInput"
                        name="chatInput"
                        className={styles.textInput}
                        placeholder={placeholder}
                        aria-label={placeholder}
                    />
                    <button
                        type="submit"
                        className={styles.sendButton}
                        aria-label="Send message"
                    >
                        <svg
                            className={styles.sendIcon}
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                        >
                            <path d="M20.5401 2.82295C21.5561 2.46795 22.5321 3.44395 22.1771 4.45995L16.2521 21.39C15.8671 22.488 14.3371 22.55 13.8651 21.487L11.0061 15.055L15.0301 11.03C15.1626 10.8878 15.2347 10.6997 15.2313 10.5054C15.2278 10.3111 15.1491 10.1257 15.0117 9.98833C14.8743 9.85092 14.6889 9.77221 14.4946 9.76878C14.3003 9.76535 14.1123 9.83747 13.9701 9.96995L9.9451 13.994L3.5131 11.135C2.4501 10.662 2.5131 9.13295 3.6101 8.74795L20.5401 2.82295Z" fill="#737478" />
                        </svg>
                    </button>
                </div>
            </form>
        </section>
    );
};
