import React, { FC } from "react";
import styles from "./QuestionCardGrid.module.scss";
import { QuestionCard } from "./QuestionCard";
import { QuestionsGridProps } from "Maya/types";

/**
 * Renders a grid of question cards within a section element.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleSubmit - The function to handle form submission.
 * @param {React.RefObject} props.inputRef - A reference to the input element.
 *
 * @returns {JSX.Element} A section containing a grid of question cards.
 */
export const QuestionsGrid: FC<QuestionsGridProps> = ({ handleSubmit, inputRef }) => (
    <section className={styles.container}>
        <div className={styles.row}>
            <QuestionCard {...{
                title: "How do I",
                question: "network like a pro?",
                handleSubmit,
                inputRef
            }} />
            <QuestionCard {...{
                title: "How do I",
                question: "ask my manager for feedback?",
                handleSubmit,
                inputRef
            }} />
        </div>
        <div className={styles.row}>
            <QuestionCard {...{
                title: "How do I",
                question: "evaluate a job offer and ask for time to think about it?",
                handleSubmit,
                inputRef
            }} />
            <QuestionCard {...{
                title: "What is",
                question: "the hidden job market and how do I access it?",
                handleSubmit,
                inputRef
            }} />
        </div>
    </section>
);
